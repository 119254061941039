import React from 'react'
import { Hero, Services } from '../'
export const Home = () => {
    return (
        <div className="flex flex-wrap items-center justify-center">
            <Hero />
            {/* <Services /> */}
        </div>
    )
}
